.container {
    height: 100px;
    width: 100%;
}

.text {
    height: 70px;
    background-color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.finish {
    height: 30px;
    background-color: #ff7b00;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}

.finish:hover {
    background-color: #ff6b00;
    color: #eee;
}

.finish:active {
    background-color: #ff5b00;
    color: #ddd;
}

.cancel {
    order: 3;
    width: 50px;
    height: 100%;
    float: right;
    background-color: #ff9b00;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancel:hover {
    color: #eee;
    background-color: #ff8b00;
}

.cancel:active {
    color: #ddd;
    background-color: #ff7b00;
}
