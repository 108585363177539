.footer {
    width: 100%;
    height: 100px;
    margin: auto;
    background-color: rebeccapurple;
}

.question-container {
    transition: 500ms;
    width: 100%;
    z-index: 1;
    transition-timing-function: ease-in-out;
}

.container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-flow: row;
}

.question-container.left {
    right: 100%;
    position: absolute;
    background-color: cadetblue;
}

.question-container.right {
    left: 100%;
    position: absolute;
    background-color: tomato;
}

.question-container.left.active {
    right: 0;
}

.question-container.right.active {
    left: 0;
}

.button {
    width: 50%;
    height: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button:hover {
    color: #eee;
}

.button:active {
    color: #ddd;
}

.button.primary {
    background-color: #ff7b00;
}

.button.primary:hover {
    background-color: #ff6b00;
}

.button.primary:active {
    background-color: #ff5b00;
}

.button.disabled {
    background-color: #aaa;
    color: #777;
}