.header {
    height: 50px;
    background-color: #ff8000;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.icon {
    color: white;
    margin: 5px;
}

.icon:hover {
    color: #eee;
}

.icon:active {
    color: #ddd;
}

.visibility {
    order: 2;
}

.refresh {
    order: 1;
}
