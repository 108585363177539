.container {
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    width: 400px;
    margin-top: -30vh;
    height: 60vh;
    background-color: #ff9b00;
}

.container.revealed {
    display: flex;
}