.img {
    width: 80px;
    height: 80px;
    transition-property: opacity;
    transition-duration: 200ms;
    background-color: white;
    padding: 5px;
    display: block;
    object-fit: contain
}

.container {
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.text {
    margin: 10px 0;
}