.container {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
}

.header {
    order: 1;
    flex: 0 0 auto;
}

.counter {
    order: 2;
    flex: 0 0 auto;
    height: 25px;
}

.grid {
    order: 3;
    flex: 1 1 auto;
}

.footer {
    order: 4;
    flex: 0 0 auto;
}
